.faq {
  background-color: var(--color-purple-light);

}

.questions {
  /* display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px 4%;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: min-content;
    font-size: var(--fz);
    font-weight: 400;
    padding: 15px;
    background: rgba(142, 114, 246, 0.1);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: 0.4s;
    flex-basis: 48%;

    &:hover {
      background: rgba(142, 114, 246, 0.3);
    }
    @media (--md) {
      flex-basis: 100%;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;

    &:after {
      content: '';
      display: inline-block;
      background: url(../img/arrow.svg) center / contain no-repeat;
      width: 10px;
      height: 21px;
      font-size: 13px;
      color: #777;
      margin-left: 5px;
    }
  }
  &__answer {
    height: 0;
    overflow: hidden;
    transition: height .5s;
  }

  @media (--md) {
    gap: 15px;
  }
}