.browser-mob {
  display: none;

  &__content {
    --width-mob: 270px;
    position: relative;
    /* background: url(../img/phone.png) top / contain no-repeat; */
    width: var(--width-mob);
    height: calc(2.03 * var(--width-mob));
    max-height: 646px;
    /* border-radius: 10px; */
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    /* padding: 7px 9px; */

    & > img {
      display: none; /* нужно для унификации работы lazy load */
      position: absolute;
      opacity: 0;
      visibility: hidden;
      object-fit: contain;
      width: 100%;
      position: absolute;
      height: calc(100% - 16px);
      /* left: 9px;
      right: 9px;
      top: 8px;
      bottom: 8px; */
      border-radius: 21px;
      transition: all 1s ease-in-out;

      @media (--md) {
        display: block;
      }
    }

    & > .active {
      opacity: 1;
      visibility: visible;
      transition: all .5s ease-in-out;
    }
  }
  &__i {
    display: none;

    @media (--md) {
      position: absolute;
      left: 48%;
      top: 12%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background-color: var(--color-purple-text);
      color: #fff;
      font-size: 10px;
      border-radius: 50%;
    }
    @media (--xs) {
      top: 9%;
      left: 46%;
    }

  }

  @media (--md) {
    display: block;
  }
}