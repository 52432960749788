.header {
  --h_nav: 86px;
  &__nav {
    --h_burger: 35px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--h_nav);
    border-bottom: 1px solid #F9F9F9;
    background-color: rgba(252, 252, 255, .95);
    transition: all .4s ease-in-out;
    z-index: 999;

    &-fixed {
      transform: translateY(-100%);
    }

    /* логика адаптива для панели навигации в header */
    & .navpanel {    
      &__container {
        max-width: var(--content-width);
        padding-right: var(--container-padding-w);
        padding-left: var(--container-padding-w);
        margin-right: auto;
        margin-left: auto;
      }  
      &__logo {
        & > p {
          @media (--lg) {
            display: none;
          }
        }
      }
      &__login {
        & > .btn {
          @media (--lg) {
            display: none;
          }
        }
      }
      &__phone {
        @media (--xs) {
          display: none;
        }
      }
      &__mobile {
        display: none;

        @media (--lg) {
          display: flex;
          gap: 10px;
          height: var(--h_burger);
        }

        & > .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
        }
      }
      &__burger {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--h_burger);
        height: var(--h_burger);
        border-radius: 50%;
        border: 1px solid var(--color-black);

        & > input[type=checkbox] {
          display: none;

          &:checked ~ {
            & label > .burger__icon {
              background: transparent;
    
              &::before,
              &::after {
                  top: 0;
              }
    
              &::after {
                transform: rotate(-45deg);
              }
    
              &::before {
                transform: rotate(45deg);
              }
            }
          }

          &+label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: var(--h_burger);
            width: 15px;
            cursor: pointer;
            transition: .4s;

            & > .burger_icon {
              background: var(--color-black);
              display: block;
              height: 1px;
              position: relative;
              transition: .4s;
              width: 90%;
    
              &::before {
                top: -5px;
              }
              &::after {
                top: 5px;
              }
              &:before, &:after {
                background: var(--color-black);
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                transition: .4s;
                width: 100%;
              }
            }
          }
        }
      }
      &__menu {
        display: none;

        @media (--lg) {
          position: absolute;
          top: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 0;
          width: 100%;
          /* padding: 15px; */
          opacity: 1;
          background-color: #f5f5f6;
          border-radius: 5px;
          overflow: hidden;
          transition: .5s;
          transition-delay: .2s;
          z-index: 100;
        }

        & > p {
          font-weight: 500;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        & > ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
          margin-bottom: 25px;
  
          & > li > a {
            display: block;
            width: 100%;
            text-align: center;
            padding: 5px 10px;
            font-weight: 400;
            font-size: var(--fz);
            border-radius: var(--border-radius);
            transition: all .4s ease;

            &:hover, &:focus {
              color: var(--color-purple-text);
              background: rgba(142, 114, 246, 0.1);
            }
          }
        }
        & > .navpanel__phone {
          display: block;
          margin-bottom: 15px;
        }
        & > .btn {
          margin-top: 10px;
        }
      }
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: var(--h_nav);

    &-left {
      margin-top: 30px;
      flex-basis: 51%;

      & > nav {
        margin-bottom: 60px;

        & > ul {
          display: flex;
          justify-content: space-between;
          gap: 5px;
  
          & > li {
            & > a {
              padding: 10px 20px;
              font-weight: 400;
              font-size: var(--fz);
              border-radius: var(--border-radius);
              transition: all .4s ease;
  
              &:hover, &:active, &:focus {
                color: var(--color-purple-text);
                background: rgba(142, 114, 246, 0.1);
              }
            }
            & .link-active {
              color: var(--color-purple-text);
              background: rgba(142, 114, 246, 0.1);
            }
          }
        }

        @media (--lg) {
          display: none;
        }
      }
      & > h1 {
        font-weight: 500;
        font-size: 35px;
        line-height: 1.4;
        margin-bottom: 20px;

        & > strong {
          position: relative;
          display: inline-block;
          padding: 0 5px;
          color: #fff;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: inherit;
            width: 100%;
            height: 100%;
            background: var(--color-purple-text);
            transform: rotate(-1deg);
            z-index: -1;
          }

        }

        @media (--lg) {
          font-size: 28px;
        }
      }
      & > ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: var(--fz);
        margin-bottom: 30px;

        & > li {

          &:before {
            content: url(../img/circles.svg);
            margin-right: 10px;
          }
        }
      }

      @media (--md) {
        margin-top: 50px;
      }
    }
    &-right {
      position: relative;
      width: 555px;
      height: min-content; /* для сохранения позиций составных картинок */
      margin-top: 20px;


      & .infogragh {
        position: relative;
        object-fit: contain;
        width: 100%;
        z-index: 10;

        &-go {
          position: absolute;
          width: 30%;
        }
      }

      & #infogragh {
        &_1 {
          left: 10%;
          bottom: 2%;
          z-index: 20;
          /* animation params */
          opacity: 0;
          animation: show_1 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
        }
        &_2 {
          left: 31%;
          z-index: 20;
          /* animation params */
          opacity: 0;
          animation: show_2 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
        }
        &_3 {
          right: 6%;
          z-index: 5;
          /* animation params */
          opacity: 0;
          animation: show_3 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
        }
      }
      @media (--lg) {
        width: 50%;
      }
      @media (--md) {
        width: 70%;
      }
      @media (--xs) {
        width: 100%;
      }
    }

    @media (--md) {
      flex-direction: column;
    }
  }

  &__form {
    width: 87%;

    @media (--md) {
      width: 50%;
    }
    @media (--xs) {
      width: 100%;
    }
  }

  @media (--xs) {
    --h_nav: 75px;
  }

  &-reestr {
    position: absolute;
    right: 0;
    bottom: 8%;
    z-index: 50;
    transition: transform .3s ease-in;
    &:hover {
      transform: scale(0.97);
    }

    @media (--xl) {
      bottom: 0;
    }

    &__img {
      height: 60px;
      object-fit: contain;
      box-shadow: 0px 8px 23px 0px #3e2c461a;

      @media (--sm) {
        width: 120px;
        height: auto;
      }
    }
  }
}

@keyframes show_1{
  from{
    transform: translate(40%, 40%);
    opacity:0;
  }
  to {
    transform: translate(0);
    opacity:1;
  }
}
@keyframes show_2{
  from{
    transform: translate(-20%, -20%);
    opacity:0;
  }
  to {
    transform: translate(0);
    opacity:1;
  }
}
@keyframes show_3{
  from{
    transform: translate(20%, 40%);
    opacity:0;
  }
  to {
    transform: translate(0);
    opacity:1;
  }
}