.btn {
  display: inline-block;
  font-weight: 500;
  font-size: var(--fz-btn);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: all .4s ease-in-out;
  position: relative;
  overflow: hidden;

  &-orange {
    background-color: var(--color-orange);
    color: #fff;
    box-shadow: 0px 8px 13px rgba(255, 195, 19, 0.3);

    &:hover {
      background-color: #FFB949;
      box-shadow: none;
      color: #fff;
    }
  }
  &-white {
    background-color: transparent;
    color: var(--color-black);
    border: 1px solid #EEEEEE;
  }
  &-s {
    padding: 10px 20px;
  }
  &-m {
    padding: 15px 30px;
  }
  &-xl {
    padding: 20px 60px;
  }

  & > .flare {
      position: absolute;
      top: 0;
      height: 100%;
      width: 45px;
      transform: skewX(-45deg);
      animation: flareAnimation;
      left: -150%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
      animation: flareAnimation 4s linear 5s infinite;
  }
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}