.footer {
  background-color: #222;

  &__container {
    padding-top: 30px;
    padding-bottom: 30px!important;
  }

  & .navpanel {
    color: #fff;

    & .btn-white {
      color: inherit;
    }
    &__container {

      @media (--lg) {
        flex-wrap: wrap;
      }
      @media (--sm) {
        flex-direction: column;
        gap: 25px;
      }
    }
    &__login > .btn{
      @media (--xs) {
        font-size: 10px;
      }
    }

  }

  &__links {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 12px;

    &:last-child {
      margin-top: 20px;
    }

    & > * {
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 10px;

        @media (--sm) {
          margin-right: 0;
        }
      }

      @media (--sm) {
        margin-bottom: 0;
      }
    }

    & a {
      text-decoration: underline;
      transition: all .4s ease;

      &:hover {
        color: #b0b0b0;
      }
    }

    @media (--sm) {
      flex-direction: column;
      gap: 10px;
    }
  }
}