.opportunities {
  &__head {
    max-width: 718px;
  }
  &__body {
    position: relative;
    text-align: center;

    & > .btn {
      position: relative;
      margin-top: -20px;

      @media (--md) {
        margin-top: 40px;
      }
      @media (--sm) {
        margin-top: 80px;
      }
    }
    & .mobile {
      @media (--md) {
        position: absolute;
        display: none;
        top: 40px;
        color: var(--color-black);
      }
    }
  }
  &__nav {
    display: none;

    @media (--lg) {
      display: block;
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
    }
    @media (--xs) {
      width: 72%;
      font-size: 13px;
    }
  }
  &__item {
    position: absolute;
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #F8F8FA;
    box-sizing: border-box;
    box-shadow: 0px 30px 40px rgba(62, 44, 70, 0.1);
    border-radius: var(--border-radius);
    text-align: left;
    z-index: 200;

    &-menu {
      width: 215px;
      right: -0.7%;
      top: 13%;

      & > ul {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        & > .opportunities-desktop-li {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          & > input[type=radio] {
            opacity: 0;
            position: fixed;
            width: 0;
          }
          & > label {
            display: block;
            cursor: pointer;
            padding: 10px;
            user-select: none;
            border-radius: var(--border-radius);
            transition: all .4s ease;

            & > svg {
              color: #eee;
              margin-right: 7px;
            }

            @media (--xl) {
              padding: 7px;
            }
          }
           
          /* Checked */
          & > input[type=radio]:checked + label {
            background: rgba(142, 114, 246, .1);
            color: var(--color-purple-text);

            svg {
              transition: all .4s ease;
              color: var(--color-purple-text);
            }
          }
           
          /* Hover */
          & > label:hover {
            background: rgba(142, 114, 246, .2);
            color: var(--color-purple-text);

            & svg {
              transition: all .4s ease;
              color: var(--color-purple-text);
            }
          }
           
          /* Disabled */
          & > input[type=radio]:disabled + label {
            background: #efefef;
            color: #666;
          }
        }
      }

      @media(--lg) {
        display: none;
      }
    }
    &-info {
      top: 17%;
      padding-top: 25px;
      padding-bottom: 25px;
      width: 230px;
      font-size: 12px;

      & > h4 {
        font-weight: 500;
        font-size: var(--fz);
        margin-bottom: 20px;
      }
      & > p {
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 20px;
      }
      & > .oppo_desc {
        display: none;
      }
      & > button {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 10px;
        background: #F8F8FA;
        border-radius: 5px;
      }

      @media (--xl) {
        top: 13%;
        width: 280px;
      }
      @media (--lg) {
        display: none;
      }
    }
    &-video {
      bottom: 11%;
      left: 5%;
      padding-left: 65px;
      font-size: 12px;
      z-index: 50;


      @media (--md) {
        padding-left: 0;
        left: 50%;
        right: 50%;
        bottom: 22%;
      }


      & > p {
        @media (--md) {
          display: none;
        }
      }
    }
  }
}

.video {
  --size-video: 65px;
  position: absolute;
  left: -26px;
  top: -7px;

  @media (--md) {
    --size-video: 52px;
  }
}
.play-wrap {
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(244, 154, 18, 0.7), rgba(244, 154, 18, 0.7)), url(../img/play_prew.png) no-repeat;
  width: var(--size-video);
  height: var(--size-video);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s;
  box-shadow: -23px -23px 71px 0px rgb(238 26 36 / 29%);

  &:before {
    width: calc(var(--size-video) + 35px);
    height: calc(var(--size-video) + 35px);
    border: 2px solid #F1B55B;
    position: absolute;
    content: "";
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation-name: rotateRevers;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-timing-function: linear;
  }
  &:after {
    width: calc(var(--size-video) + 15px);
    height: calc(var(--size-video) + 15px);
    border: 2px solid #F1B55B;
    position: absolute;
    content: "";
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-timing-function: linear;
  }
  & > img {
    width: 30%;
    object-fit: contain;
  }
}

.my-select {
  /* reset */
  border: none;
  margin: 0;
  width: 100%;
  outline: none;
  cursor: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* style */
  background-image: linear-gradient(45deg, transparent 50%, #8E72F6 50%),
    linear-gradient(135deg, #8E72F6 50%, transparent 50%);
  background-position: calc(100% - 18px) calc(1em + 6px),
  calc(100% - 10px) calc(1em + 6px);
  background-size: 6px 6px, 9px 6px;
  background-repeat: no-repeat;
  background-color: rgba(142, 114, 246, 0.1);
  color: #8E72F6;
  border-radius: var(--border-radius);
  display: inline-block;
  font: inherit;
  text-align: center;
  line-height: 1.5em;
  padding: 15px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &::-ms-expand {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }

  @media (--xs) {
    padding: 11px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateRevers {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}