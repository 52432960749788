@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

@import "./reset.css";
@import "./blocks/*.css";
@import "./components/*.css";

html {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: 16px;
  @media (--sm) {
    font-size: 14px;
  }
}
b {
  font-weight: 600;
}
:root {
  /* color */
  --color-white: #FCFCFF;
  --color-black: #3E2C46;
  --color-orange: #F49A12;
  --color-purple: #7A76E5;
  --color-purple-light: #F0ECFF;
  --color-purple-text: #8E72F6;
  /* font  */
  --fz: 14px;
  --fz-btn: 16px;
  --fz-h4: 18px;
  --fz-h2: 28px;
  /* content */
  --content-width: 1200px;
  --container-padding-h: 80px;
  --container-padding-w: 30px;
  --border-radius: 5px;

  @media (--xl) {
    
  }
  @media (--lg) {
    --fz-h2: 24px;
  }
  @media (--md) {

    --container-padding-w: 20px;
  }
  @media (--sm) {
    --container-padding-w: 10px;
    --container-padding-h: 50px;
  }
  @media (--xs) {
    --fz-h2: 20px;
  }
}
.container {
  max-width: var(--content-width);
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  padding-bottom: var(--container-padding-h);
}
.head {
  text-align: center;
  padding-top: var(--container-padding-h);
  padding-bottom: 60px;
  margin-right: auto;
  margin-left: auto;

  & > h2 {
    font-weight: 700;
    font-size: var(--fz-h2);
    line-height: 1.5;
  }
  & > p {
    margin-top: 20px;
  }

  &_white {
    color: #fff;
  }
}
.item-wrapper {
  background: linear-gradient(0deg, #FCFCFF, #FCFCFF), #F9F9F9;
  border: 1px solid rgba(122, 118, 229, 0.5);
  box-shadow: 0px 10px 30px rgba(62, 44, 70, 0.1);
  border-radius: calc(2 * var(--border-radius));
}

/* Animation */
*[data-animate-on-scroll] {
  opacity: 0;
}
*[data-animate-on-scroll=animated] {
  opacity: 1;
  animation: wipe-enter 2s;
}

@keyframes wipe-enter {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}