@import "https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.5;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input {
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
}

a:active, a:hover, a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

::-webkit-search-decoration {
  display: none;
}

.navpanel {
  --h_nav: 86px;
}

.navpanel__container {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.navpanel__logo {
  --margin: 20px;
  align-items: center;
  display: flex;
}

.navpanel__logo > img {
  margin-right: var(--margin);
}

.navpanel__logo > p {
  font-size: 12px;
  position: relative;
}

.navpanel__logo > p:before {
  content: "";
  left: calc(var(--margin) / -2);
  width: 1px;
  height: 100%;
  background-color: #ffffff26;
  position: absolute;
}

.navpanel__phone-top {
  font-weight: 500;
  font-size: var(--fz-h4);
  align-items: center;
  display: flex;
}

.navpanel__phone-top > :first-child {
  margin-right: 10px;
}

.navpanel__phone > button {
  margin-left: 28px;
  font-size: 10px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  display: block;
}

.navpanel__login {
  display: flex;
}

.navpanel__login > .btn {
  font-weight: 400;
  font-size: var(--fz);
}

.navpanel__login > .btn:first-child {
  margin-right: 10px;
}

.header {
  --h_nav: 86px;
}

.header__nav {
  --h_burger: 35px;
  width: 100%;
  height: var(--h_nav);
  z-index: 999;
  background-color: #fcfcfff2;
  border-bottom: 1px solid #f9f9f9;
  transition: all .4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.header__nav-fixed {
  transform: translateY(-100%);
}

.header__nav .navpanel__container {
  max-width: var(--content-width);
  padding-right: var(--container-padding-w);
  padding-left: var(--container-padding-w);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 900px) {
  .header__nav .navpanel__logo > p {
    display: none;
  }
}

@media (max-width: 900px) {
  .header__nav .navpanel__login > .btn {
    display: none;
  }
}

@media (max-width: 480px) {
  .header__nav .navpanel__phone {
    display: none;
  }
}

.header__nav .navpanel__mobile {
  display: none;
}

@media (max-width: 900px) {
  .header__nav .navpanel__mobile {
    height: var(--h_burger);
    gap: 10px;
    display: flex;
  }
}

.header__nav .navpanel__mobile > .btn {
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.header__nav .navpanel__burger {
  width: var(--h_burger);
  height: var(--h_burger);
  border: 1px solid var(--color-black);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header__nav .navpanel__burger > input[type="checkbox"] {
  display: none;
}

.header__nav .navpanel__burger > input[type="checkbox"]:checked ~ label > .burger__icon {
  background: none;
}

.header__nav .navpanel__burger > input[type="checkbox"]:checked ~ label > .burger__icon:before, .header__nav .navpanel__burger > input[type="checkbox"]:checked ~ label > .burger__icon:after {
  top: 0;
}

.header__nav .navpanel__burger > input[type="checkbox"]:checked ~ label > .burger__icon:after {
  transform: rotate(-45deg);
}

.header__nav .navpanel__burger > input[type="checkbox"]:checked ~ label > .burger__icon:before {
  transform: rotate(45deg);
}

.header__nav .navpanel__burger > input[type="checkbox"] + label {
  height: var(--h_burger);
  width: 15px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all .4s;
  display: flex;
}

.header__nav .navpanel__burger > input[type="checkbox"] + label > .burger_icon {
  background: var(--color-black);
  height: 1px;
  width: 90%;
  transition: all .4s;
  display: block;
  position: relative;
}

.header__nav .navpanel__burger > input[type="checkbox"] + label > .burger_icon:before {
  top: -5px;
}

.header__nav .navpanel__burger > input[type="checkbox"] + label > .burger_icon:after {
  top: 5px;
}

.header__nav .navpanel__burger > input[type="checkbox"] + label > .burger_icon:before, .header__nav .navpanel__burger > input[type="checkbox"] + label > .burger_icon:after {
  background: var(--color-black);
  content: "";
  height: 1px;
  width: 100%;
  transition: all .4s;
  display: block;
  position: absolute;
}

.header__nav .navpanel__menu {
  display: none;
}

@media (max-width: 900px) {
  .header__nav .navpanel__menu {
    height: 0;
    width: 100%;
    opacity: 1;
    z-index: 100;
    background-color: #f5f5f6;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    transition: all .5s .2s;
    display: flex;
    position: absolute;
    top: 100%;
    overflow: hidden;
  }
}

.header__nav .navpanel__menu > p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.header__nav .navpanel__menu > ul {
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 25px;
  display: flex;
}

.header__nav .navpanel__menu > ul > li > a {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: var(--fz);
  border-radius: var(--border-radius);
  padding: 5px 10px;
  transition: all .4s;
  display: block;
}

.header__nav .navpanel__menu > ul > li > a:hover, .header__nav .navpanel__menu > ul > li > a:focus {
  color: var(--color-purple-text);
  background: #8e72f61a;
}

.header__nav .navpanel__menu > .navpanel__phone {
  margin-bottom: 15px;
  display: block;
}

.header__nav .navpanel__menu > .btn {
  margin-top: 10px;
}

.header__main {
  width: 100%;
  padding-top: var(--h_nav);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__main-left {
  flex-basis: 51%;
  margin-top: 30px;
}

.header__main-left > nav {
  margin-bottom: 60px;
}

.header__main-left > nav > ul {
  justify-content: space-between;
  gap: 5px;
  display: flex;
}

.header__main-left > nav > ul > li > a {
  font-weight: 400;
  font-size: var(--fz);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  transition: all .4s;
}

.header__main-left > nav > ul > li > a:hover, .header__main-left > nav > ul > li > a:active, .header__main-left > nav > ul > li > a:focus, .header__main-left > nav > ul > li .link-active {
  color: var(--color-purple-text);
  background: #8e72f61a;
}

@media (max-width: 900px) {
  .header__main-left > nav {
    display: none;
  }
}

.header__main-left > h1 {
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.4;
}

.header__main-left > h1 > strong {
  color: #fff;
  padding: 0 5px;
  display: inline-block;
  position: relative;
}

.header__main-left > h1 > strong:before {
  content: "";
  display: inherit;
  width: 100%;
  height: 100%;
  background: var(--color-purple-text);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-1deg);
}

@media (max-width: 900px) {
  .header__main-left > h1 {
    font-size: 28px;
  }
}

.header__main-left > ul {
  font-size: var(--fz);
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
}

.header__main-left > ul > li:before {
  content: url("circles.c8f8fae0.svg");
  margin-right: 10px;
}

@media (max-width: 768px) {
  .header__main-left {
    margin-top: 50px;
  }
}

.header__main-right {
  width: 555px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: -webkit-min-content;
  height: min-content;
  margin-top: 20px;
  position: relative;
}

.header__main-right .infogragh {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  z-index: 10;
  position: relative;
}

.header__main-right .infogragh-go {
  width: 30%;
  position: absolute;
}

.header__main-right #infogragh_1 {
  z-index: 20;
  opacity: 0;
  animation: show_1 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
  bottom: 2%;
  left: 10%;
}

.header__main-right #infogragh_2 {
  z-index: 20;
  opacity: 0;
  animation: show_2 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
  left: 31%;
}

.header__main-right #infogragh_3 {
  z-index: 5;
  opacity: 0;
  animation: show_3 3s cubic-bezier(.5, 1, .7, 1) 1.2s forwards;
  right: 6%;
}

@media (max-width: 900px) {
  .header__main-right {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .header__main-right {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .header__main-right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .header__main {
    flex-direction: column;
  }
}

.header__form {
  width: 87%;
}

@media (max-width: 768px) {
  .header__form {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .header__form {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header {
    --h_nav: 75px;
  }
}

.header-reestr {
  z-index: 50;
  transition: transform .3s ease-in;
  position: absolute;
  bottom: 8%;
  right: 0;
}

.header-reestr:hover {
  transform: scale(.97);
}

@media (max-width: 1100px) {
  .header-reestr {
    bottom: 0;
  }
}

.header-reestr__img {
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
  box-shadow: 0 8px 23px #3e2c461a;
}

@media (max-width: 650px) {
  .header-reestr__img {
    width: 120px;
    height: auto;
  }
}

@keyframes show_1 {
  from {
    opacity: 0;
    transform: translate(40%, 40%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes show_2 {
  from {
    opacity: 0;
    transform: translate(-20%, -20%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes show_3 {
  from {
    opacity: 0;
    transform: translate(20%, 40%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

.statistics {
  position: relative;
}

.statistics__container {
  padding-bottom: 0 !important;
}

.statistics__body:before {
  content: "";
  width: 100%;
  height: 115px;
  background-color: var(--color-purple);
  z-index: -2;
  position: absolute;
  bottom: -1px;
  left: 0;
}

@media (max-width: 480px) {
  .statistics__body:before {
    height: 70px;
  }
}

.list-statistics {
  justify-content: center;
  display: flex;
}

.list-statistics__item {
  width: 270px;
  height: 230px;
  text-align: center;
  white-space: pre-wrap;
  background: url("bg.448367ee.svg") center / contain no-repeat;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 480px) {
  .list-statistics__item:first-child {
    flex-basis: 100%;
    order: 0;
  }
}

@media (max-width: 480px) {
  .list-statistics__item:nth-child(2) {
    order: 3;
  }
}

.list-statistics__item > p {
  color: #7a76e5;
  margin-top: 40px;
  font-size: 48px;
  font-weight: bold;
}

@media (max-width: 900px) {
  .list-statistics__item > p {
    font-size: 38px;
  }
}

@media (max-width: 650px) {
  .list-statistics__item > p {
    margin-top: 60px;
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .list-statistics__item > p {
    margin-top: 15px;
  }
}

.list-statistics__item > span {
  font-weight: 500;
  font-size: var(--fz);
}

@media (max-width: 650px) {
  .list-statistics__item > span {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .list-statistics__item > span {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .list-statistics__item {
    width: 150px;
    height: 130px;
  }
}

@media (max-width: 480px) {
  .list-statistics {
    flex-wrap: wrap;
  }
}

.problems {
  background-color: var(--color-purple);
}

@media (max-width: 650px) {
  .problems__body > .card-problems {
    display: none;
  }
}

.problems__head {
  max-width: 796px;
}

.problems__swiper {
  display: none;
}

@media (max-width: 650px) {
  .problems__swiper {
    display: block;
  }
}

.problems__swiper-item {
  height: auto !important;
}

.problems__swiper .card-problems__item {
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  display: flex;
}

.problems__swiper .card-problems__item > img {
  float: none;
  align-self: auto;
}

.problems__nav {
  display: none;
}

@media (max-width: 650px) {
  .problems__nav {
    justify-content: center;
    margin-top: 20px;
    display: flex;
  }
}

.problems__nav > .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background-color: #0000;
  border: 1px solid #fff;
  margin-right: 10px;
}

.problems__nav > .swiper-pagination-bullet:first-child {
  margin-left: 10px;
}

.problems__nav > .swiper-pagination-bullet-active {
  background: #fff;
}

.card-problems {
  display: grid;
}

.card-problems:first-child {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.card-problems:nth-child(2) {
  grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
  gap: 15px;
}

@media (max-width: 900px) {
  .card-problems:nth-child(2) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
}

.card-problems__item {
  font-size: var(--fz);
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  font-weight: 600;
  transition: all .5s ease-in-out;
  display: flex;
}

.card-problems__item > p {
  white-space: pre-wrap;
  margin-bottom: 15px;
}

.card-problems__item > img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 80px;
  float: right;
  align-self: flex-end;
}

.card-problems__item:hover {
  transform: scale(1.05);
}

.opportunities__head {
  max-width: 718px;
}

.opportunities__body {
  text-align: center;
  position: relative;
}

.opportunities__body > .btn {
  margin-top: -20px;
  position: relative;
}

@media (max-width: 768px) {
  .opportunities__body > .btn {
    margin-top: 40px;
  }
}

@media (max-width: 650px) {
  .opportunities__body > .btn {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .opportunities__body .mobile {
    color: var(--color-black);
    display: none;
    position: absolute;
    top: 40px;
  }
}

.opportunities__nav {
  display: none;
}

@media (max-width: 900px) {
  .opportunities__nav {
    width: 50%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (max-width: 480px) {
  .opportunities__nav {
    width: 72%;
    font-size: 13px;
  }
}

.opportunities__item {
  box-sizing: border-box;
  border-radius: var(--border-radius);
  text-align: left;
  z-index: 200;
  background: #fff;
  border: 1px solid #f8f8fa;
  padding: 15px;
  position: absolute;
  box-shadow: 0 30px 40px #3e2c461a;
}

.opportunities__item-menu {
  width: 215px;
  top: 13%;
  right: -.7%;
}

.opportunities__item-menu > ul {
  flex-direction: column;
  font-size: 12px;
  display: flex;
}

.opportunities__item-menu > ul > .opportunities-desktop-li:not(:last-child) {
  margin-bottom: 5px;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--border-radius);
  padding: 10px;
  transition: all .4s;
  display: block;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > label > svg {
  color: #eee;
  margin-right: 7px;
}

@media (max-width: 1100px) {
  .opportunities__item-menu > ul > .opportunities-desktop-li > label {
    padding: 7px;
  }
}

.opportunities__item-menu > ul > .opportunities-desktop-li > input[type="radio"]:checked + label {
  color: var(--color-purple-text);
  background: #8e72f61a;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > input[type="radio"]:checked + label svg {
  color: var(--color-purple-text);
  transition: all .4s;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > label:hover {
  color: var(--color-purple-text);
  background: #8e72f633;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > label:hover svg {
  color: var(--color-purple-text);
  transition: all .4s;
}

.opportunities__item-menu > ul > .opportunities-desktop-li > input[type="radio"]:disabled + label {
  color: #666;
  background: #efefef;
}

@media (max-width: 900px) {
  .opportunities__item-menu {
    display: none;
  }
}

.opportunities__item-info {
  width: 230px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 12px;
  top: 17%;
}

.opportunities__item-info > h4 {
  font-weight: 500;
  font-size: var(--fz);
  margin-bottom: 20px;
}

.opportunities__item-info > p {
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.4;
}

.opportunities__item-info > .oppo_desc {
  display: none;
}

.opportunities__item-info > button {
  width: 100%;
  text-align: center;
  background: #f8f8fa;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
}

@media (max-width: 1100px) {
  .opportunities__item-info {
    width: 280px;
    top: 13%;
  }
}

@media (max-width: 900px) {
  .opportunities__item-info {
    display: none;
  }
}

.opportunities__item-video {
  z-index: 50;
  padding-left: 65px;
  font-size: 12px;
  bottom: 11%;
  left: 5%;
}

@media (max-width: 768px) {
  .opportunities__item-video {
    padding-left: 0;
    bottom: 22%;
    left: 50%;
    right: 50%;
  }
}

@media (max-width: 768px) {
  .opportunities__item-video > p {
    display: none;
  }
}

.video {
  --size-video: 65px;
  position: absolute;
  top: -7px;
  left: -26px;
}

@media (max-width: 768px) {
  .video {
    --size-video: 52px;
  }
}

.play-wrap {
  width: var(--size-video);
  height: var(--size-video);
  cursor: pointer;
  background: linear-gradient(#f49a12b3, #f49a12b3), url("play_prew.fa9ebcdb.png") no-repeat;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .4s;
  display: flex;
  position: relative;
  left: 0;
  right: 0;
  box-shadow: -23px -23px 71px #ee1a244a;
}

.play-wrap:before {
  width: calc(var(--size-video)  + 35px);
  height: calc(var(--size-video)  + 35px);
  content: "";
  border: 2px solid #f1b55b;
  border-color: #f1b55b #0000;
  border-radius: 50%;
  animation-name: rotateRevers;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}

.play-wrap:after {
  width: calc(var(--size-video)  + 15px);
  height: calc(var(--size-video)  + 15px);
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #f1b55b;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}

.play-wrap > img {
  width: 30%;
  -o-object-fit: contain;
  object-fit: contain;
}

.my-select {
  width: 100%;
  cursor: inherit;
  -webkit-appearance: none;
  appearance: none;
  color: #8e72f6;
  border-radius: var(--border-radius);
  font: inherit;
  text-align: center;
  background-color: #8e72f61a;
  background-image: linear-gradient(45deg, #0000 50%, #8e72f6 50%), linear-gradient(135deg, #8e72f6 50%, #0000 50%);
  background-position: calc(100% - 18px) calc(1em + 6px), calc(100% - 10px) calc(1em + 6px);
  background-repeat: no-repeat;
  background-size: 6px 6px, 9px 6px;
  border: none;
  outline: none;
  margin: 0;
  padding: 15px;
  line-height: 1.5em;
  transition: all .4s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.my-select::-ms-expand {
  display: none;
}

.my-select:hover {
  opacity: .8;
}

@media (max-width: 480px) {
  .my-select {
    padding: 11px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateRevers {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.freetrial {
  background-color: var(--color-purple-light);
}

.freetrial__container {
  padding-top: 120px;
}

.trialfrom {
  width: 100%;
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.trialfrom__img {
  height: 420px;
  margin-right: 60px;
  position: relative;
  transform: scale(1.03);
}

.trialfrom__img img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 100%;
}

@media (max-width: 900px) {
  .trialfrom__img {
    display: none;
  }
}

.trialfrom__main {
  width: 100%;
  padding: 40px 40px 40px 0;
}

.trialfrom__main > h3 {
  max-width: 480px;
  font-size: var(--fz-h2);
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.5;
}

@media (max-width: 900px) {
  .trialfrom__main > h3 {
    max-width: none;
  }
}

.trialfrom__main > p {
  max-width: 500px;
  margin-bottom: 40px;
}

@media (max-width: 900px) {
  .trialfrom__main > p {
    max-width: none;
  }
}

@media (max-width: 480px) {
  .trialfrom__main > p {
    margin-bottom: 60px;
  }
}

.trialfrom__main > .mobile {
  -o-object-fit: contain;
  object-fit: contain;
  width: 50%;
  margin-bottom: 40px;
  display: none;
}

@media (max-width: 900px) {
  .trialfrom__main > .mobile {
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .trialfrom__main > .mobile {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .trialfrom__main {
    text-align: center;
    padding: 0;
  }
}

@media (max-width: 900px) {
  .trialfrom__form {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 650px) {
  .trialfrom__form {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .trialfrom {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .trialfrom {
    padding: 30px 20px;
  }
}

.interface__body {
  position: relative;
}

@media (max-width: 768px) {
  .interface__body {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    display: flex;
  }
}

.interface__nav {
  font-size: var(--fz);
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
  display: flex;
}

.interface__nav > li > input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.interface__nav > li > label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--border-radius);
  text-align: center;
  padding: 10px 20px;
  transition: all .4s;
  display: inline-block;
}

.interface__nav > li > input[type="radio"]:checked + label {
  color: var(--color-purple-text);
  background: #8e72f61a;
}

.interface__nav > li > label:hover {
  color: var(--color-purple-text);
  background: #8e72f633;
}

.interface__nav > li > input[type="radio"]:disabled + label {
  color: #666;
  background: #efefef;
}

.interface__nav-mobile {
  display: none;
}

@media (max-width: 768px) {
  .interface__nav-mobile {
    margin-bottom: 30px;
    display: block;
  }
}

@media (max-width: 480px) {
  .interface__nav-mobile {
    width: 72%;
    margin-bottom: 10px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .interface__nav {
    display: none;
  }
}

.interface__item {
  width: 240px;
  border-radius: var(--border-radius);
  text-align: left;
  z-index: 100;
  background: #fff;
  border: 1px solid #f8f8fa;
  padding: 30px 20px;
  font-size: 12px;
  position: absolute;
  box-shadow: 0 30px 40px #3e2c461a;
}

.interface__item-info {
  color: #000;
  top: 30%;
}

@media (max-width: 1100px) {
  .interface__item-info {
    display: none;
  }
}

@media (max-width: 768px) {
  .interface__item-info {
    display: block;
  }
}

.interface__item-action {
  color: #000;
  bottom: 8%;
}

@media (max-width: 900px) {
  .interface__item-action {
    width: 200px;
    bottom: 5%;
  }
}

.interface__item > h4 {
  font-weight: 500;
  font-size: var(--fz);
  margin-bottom: 20px;
}

.interface__item > p {
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.4;
}

.interface__item > .btn {
  padding: 10px 12px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .interface__item {
    width: 50%;
    text-align: center;
    padding: 20px 15px;
    position: relative;
  }
}

@media (max-width: 480px) {
  .interface__item {
    width: 90%;
  }
}

.help {
  background-color: var(--color-purple-light);
}

.help__container {
  text-align: center;
}

.help__body {
  text-align: left;
  justify-content: space-between;
  margin-bottom: 40px;
  display: flex;
}

@media (max-width: 900px) {
  .help__body {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .help__body {
    text-align: center;
    margin-bottom: 0;
  }
}

.list-help {
  flex-basis: 46%;
  margin-right: 8%;
}

.list-help__item {
  --img-h: 70px;
  padding: 30px;
}

.list-help__item:not(:last-child) {
  margin-bottom: 75px;
}

.list-help__item > img {
  top: calc(-.85 * var(--img-h));
  -o-object-fit: contain;
  object-fit: contain;
  height: var(--img-h);
  position: relative;
}

.list-help__item > h4 {
  font-weight: 500;
  font-size: var(--fz-h4);
  color: var(--color-black);
  margin-top: calc(-.6 * var(--img-h));
  margin-bottom: 20px;
}

.list-help__item > p {
  font-size: var(--fz);
}

@media (max-width: 480px) {
  .list-help__item {
    padding: 25px;
  }
}

@media (max-width: 1100px) {
  .list-help {
    margin-right: 10px;
  }
}

@media (max-width: 900px) {
  .list-help {
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.speak-manager {
  flex-basis: 50%;
  display: flex;
  position: relative;
}

.speak-manager__card {
  width: 57%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  font-size: 12px;
  position: relative;
}

.speak-manager__card:after {
  --size: 18px;
  content: "";
  top: 42%;
  right: calc(-2 * var(--size));
  border: var(--size) solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  transform: rotate(90deg);
}

@media (max-width: 480px) {
  .speak-manager__card:after {
    top: auto;
    bottom: calc(-2 * var(--size));
    left: 50%;
    right: 50%;
    transform: rotate(180deg);
  }
}

.speak-manager__card > i {
  margin-bottom: 10px;
  font-style: italic;
  display: inline-block;
}

@media (max-width: 480px) {
  .speak-manager__card {
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.speak-manager__img {
  max-width: 348px;
  width: 100%;
  height: 482px;
  background: linear-gradient(#eeeafe00 81.98%, #eeeafe 100%), url("help_man.ce9e00ea.png") center / contain no-repeat;
  position: absolute;
  right: 0;
}

@media (max-width: 900px) {
  .speak-manager__img {
    position: relative;
  }
}

@media (max-width: 650px) {
  .speak-manager__img {
    width: 60%;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .speak-manager__img {
    height: 280px;
    background: url("help_man.ce9e00ea.png") bottom / contain no-repeat;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .speak-manager {
    flex-direction: column;
    gap: 40px;
  }
}

.economy__form {
  max-width: 556px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1100px) {
  .economy__form {
    width: 50%;
  }
}

@media (max-width: 650px) {
  .economy__form {
    width: 100%;
  }
}

.list-economy {
  justify-content: space-between;
  gap: 100px 20px;
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
}

.list-economy__item {
  --img-h: 125px;
  box-sizing: border-box;
  background: linear-gradient(0deg, #fcfcff, #fcfcff);
  border: 1px solid #eee;
  border-radius: 10px;
  flex-direction: column;
  flex-basis: 25%;
  padding: 20px;
  transition: all .5s;
  display: flex;
  position: relative;
  box-shadow: 0 10px 30px #3333331a;
}

.list-economy__item:hover {
  transform: translateY(-10px);
}

.list-economy__item > img {
  top: calc(-.75 * var(--img-h));
  -o-object-fit: contain;
  object-fit: contain;
  height: var(--img-h);
  position: relative;
}

.list-economy__item > h4 {
  font-weight: 600;
  font-size: var(--fz-h4);
  color: var(--color-black);
  margin-top: calc(-.5 * var(--img-h));
  margin-bottom: 20px;
}

.list-economy__item > p {
  font-size: var(--fz);
}

@media (max-width: 900px) {
  .list-economy__item {
    flex-basis: 47%;
  }
}

@media (max-width: 650px) {
  .list-economy__item {
    --img-h: 100px;
    text-align: center;
    flex-basis: 100%;
  }
}

@media (max-width: 900px) {
  .list-economy {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .list-economy {
    gap: 75px;
  }
}

.reasons {
  background-color: var(--color-purple-light);
}

.list-reasons {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px 12px;
  display: flex;
}

.list-reasons__item {
  flex-basis: 49%;
  padding: 30px;
  transition: all .5s;
}

.list-reasons__item:hover {
  transform: translateY(-10px);
}

.list-reasons__item:nth-child(-n+3) {
  flex-basis: 32%;
}

@media (max-width: 768px) {
  .list-reasons__item:nth-child(-n+3) {
    flex-basis: 100%;
  }
}

.list-reasons__item > img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 60px;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .list-reasons__item > img {
    margin-bottom: 20px;
  }
}

.list-reasons__item > h4 {
  font-weight: 500;
  font-size: var(--fz-h4);
  color: var(--color-black);
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .list-reasons__item > h4 {
    margin-bottom: 15px;
  }
}

.list-reasons__item > p {
  font-size: var(--fz);
}

@media (max-width: 650px) {
  .list-reasons__item {
    text-align: center;
    flex-basis: 100%;
    padding: 25px;
  }
}

@media (max-width: 480px) {
  .list-reasons {
    gap: 25px;
  }
}

.price {
  background-color: var(--color-purple-light);
  position: relative;
}

.price__container {
  padding-top: 80px;
}

@media (max-width: 1100px) {
  .price__container {
    background-color: var(--color-purple);
    border-top: 1px solid #7a76e580;
    border-bottom: 1px solid #7a76e580;
    box-shadow: 0 10px 30px #3333331a, inset 0 5px 50px #ffffff40;
  }
}

@media (max-width: 650px) {
  .price__container {
    padding-top: 50px;
  }
}

.price__body {
  justify-content: space-between;
  display: flex;
}

.price__body:before {
  content: "";
  width: 100%;
  height: 363px;
  background-color: var(--color-purple);
  border-top: 1px solid #7a76e580;
  border-bottom: 1px solid #7a76e580;
  position: absolute;
  left: 0;
  box-shadow: 0 10px 30px #3333331a, inset 0 5px 50px #ffffff40;
}

@media (max-width: 1100px) {
  .price__body:before {
    content: none  ;
  }
}

@media (max-width: 650px) {
  .price__body {
    text-align: center;
    padding: 0 10px;
  }
}

.price__img {
  max-height: 500px;
  width: 40%;
  margin-top: -52px;
  margin-left: -42px;
  position: relative;
}

.price__img > img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1100px) {
  .price__img {
    max-height: none;
    height: 350px;
    width: auto;
    margin-top: 0;
    margin-left: 0;
    margin-right: 10px;
  }
}

@media (max-width: 650px) {
  .price__img {
    display: none;
  }
}

.price__main {
  color: #fff;
  z-index: 10;
  padding-top: 60px;
}

.price__main > h2 {
  font-weight: 600;
  font-size: var(--fz-h2);
  margin-bottom: 20px;
}

.price__main > p {
  margin-bottom: 40px;
}

.price__main > .mobile {
  display: none;
}

.price__main > .mobile > img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 650px) {
  .price__main > .mobile {
    width: 65%;
    min-width: 215px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (max-width: 1100px) {
  .price__main {
    padding-top: 0;
  }
}

.price__form {
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  display: flex;
}

@media (max-width: 1100px) {
  .price__form {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .price__form {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.price__submit {
  width: 100%;
  gap: 5px;
  display: flex;
}

.price__submit > .input {
  flex-basis: 50%;
}

@media (max-width: 650px) {
  .price__submit > .input {
    padding: 15px 10px;
  }
}

.price__submit > .btn {
  flex-basis: 55%;
}

@media (max-width: 900px) {
  .price__submit {
    height: ;
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .price__submit {
    height: 100%;
  }
}

.messengers-price {
  gap: 5px;
  display: flex;
}

.messengers-price__item span {
  font-size: 10px;
  font-weight: 400;
}

.messengers-price__item > input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.messengers-price__item > label {
  cursor: pointer;
  width: 70px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--border-radius);
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
  transition: all .4s;
  display: flex;
}

.messengers-price__item > input[type="radio"]:checked + label, .messengers-price__item > label:hover {
  color: #000;
  background-color: #fff;
}

.messengers-price__item > input[type="radio"]:disabled + label {
  color: #666;
  background: #efefef;
}

.ceo {
  background-color: var(--color-purple-light);
}

.ceo__container {
  padding-top: 80px;
}

.ceo__body {
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media (max-width: 900px) {
  .ceo__body {
    padding: 30px;
  }
}

.ceo__main {
  flex-basis: 60%;
  padding: 40px;
}

.ceo__main > h4 {
  font-weight: bold;
  font-size: var(--fz-h2);
  margin-bottom: 10px;
}

.ceo__main > p {
  margin-bottom: 40px;
}

.ceo__main > i {
  font-size: var(--fz);
  margin-bottom: 40px;
  font-style: italic;
  display: inline-block;
}

.ceo__main > .mobile {
  text-align: center;
  display: none;
  position: relative;
}

.ceo__main > .mobile > img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.ceo__main > .mobile:before {
  content: "Андрей Баранов";
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: var(--border-radius);
  z-index: 5;
  background: #fffffff2;
  padding: 15px 30px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  bottom: 7%;
  left: 21%;
}

@media (max-width: 480px) {
  .ceo__main > .mobile:before {
    padding: 15px;
    bottom: 10%;
    left: 15%;
    right: 15%;
  }
}

.ceo__main > .mobile:after {
  content: "Руководитель AvirentCRM";
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: var(--border-radius);
  background: #ffffffe6;
  padding: 15px 30px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  bottom: -2%;
  left: 11%;
}

@media (max-width: 480px) {
  .ceo__main > .mobile:after {
    padding: 15px;
    bottom: 0;
    left: 3%;
    right: 3%;
  }
}

@media (max-width: 768px) {
  .ceo__main > .mobile {
    width: 324px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (max-width: 480px) {
  .ceo__main > .mobile {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .ceo__main {
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .ceo__main {
    flex-basis: 100%;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .ceo__main {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .ceo__form {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 650px) {
  .ceo__form {
    width: 100%;
  }
}

.ceo__img {
  width: 50%;
  font-size: var(--fz);
  align-items: flex-end;
  margin-top: -50px;
  padding-right: 40px;
  display: flex;
  position: relative;
}

.ceo__img > img {
  max-height: 614px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.ceo__img:before {
  content: "Андрей Баранов";
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: var(--border-radius);
  z-index: 5;
  background: #fffffff2;
  padding: 15px 30px;
  display: inline-block;
  position: absolute;
  bottom: 150px;
  left: 30%;
}

.ceo__img:after {
  content: "Руководитель AvirentCRM";
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: var(--border-radius);
  background: #ffffffe6;
  padding: 15px 30px;
  display: inline-block;
  position: absolute;
  bottom: 105px;
  left: 23%;
}

@media (max-width: 1100px) {
  .ceo__img {
    padding-right: 20px;
  }
}

@media (max-width: 900px) {
  .ceo__img {
    display: none;
  }
}

.otzovy {
  background-color: var(--color-purple-light);
}

.otzovy__swiper-item {
  --width-mob: 270px;
  width: var(--width-mob);
  height: calc(2.03 * var(--width-mob));
  max-height: 646px;
  border-radius: 10px;
  padding: 7px;
  display: flex;
  overflow: hidden;
}

.otzovy__swiper-item > img {
  -o-object-fit: contain;
  object-fit: contain;
  width: var(--width-mob);
  height: 100%;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease-in-out;
}

.otzovy__nav-prev, .otzovy__nav-next {
  --btn-nav: 30%;
  width: 40px;
  height: 40px;
  top: 50%;
  left: var(--btn-nav);
  background: url("otzovy_nav.91c7dc5f.svg") center / 25% no-repeat, var(--color-orange);
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -20px;
  position: absolute;
}

@media (max-width: 900px) {
  .otzovy__nav-prev, .otzovy__nav-next {
    --btn-nav: 20%;
  }
}

@media (max-width: 650px) {
  .otzovy__nav-prev, .otzovy__nav-next {
    --btn-nav: 0%;
  }
}

.otzovy__nav-next {
  right: var(--btn-nav);
  left: auto;
  transform: rotate(180deg);
}

.swiper-slide-prev, .swiper-slide-next {
  opacity: .5;
}

.faq {
  background-color: var(--color-purple-light);
}

.questions {
  flex-wrap: wrap;
  gap: 10px 4%;
  display: flex;
}

.questions__item {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: -webkit-min-content;
  height: min-content;
  font-size: var(--fz);
  border-radius: var(--border-radius);
  cursor: pointer;
  background: #8e72f61a;
  flex-direction: column;
  flex-basis: 48%;
  justify-content: space-between;
  padding: 15px;
  font-weight: 400;
  transition: all .4s;
  display: flex;
}

.questions__item:hover {
  background: #8e72f64d;
}

@media (max-width: 768px) {
  .questions__item {
    flex-basis: 100%;
  }
}

.questions__title {
  justify-content: space-between;
  display: flex;
}

.questions__title:after {
  content: "";
  width: 10px;
  height: 21px;
  color: #777;
  background: url("arrow.d762606f.svg") center / contain no-repeat;
  margin-left: 5px;
  font-size: 13px;
  display: inline-block;
}

.questions__answer {
  height: 0;
  transition: height .5s;
  overflow: hidden;
}

@media (max-width: 768px) {
  .questions {
    gap: 15px;
  }
}

.footer {
  background-color: #222;
}

.footer__container {
  padding-top: 30px;
  padding-bottom: 30px !important;
}

.footer .navpanel {
  color: #fff;
}

.footer .navpanel .btn-white {
  color: inherit;
}

@media (max-width: 900px) {
  .footer .navpanel__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .footer .navpanel__container {
    flex-direction: column;
    gap: 25px;
  }
}

@media (max-width: 480px) {
  .footer .navpanel__login > .btn {
    font-size: 10px;
  }
}

.footer__links {
  color: #fff;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 60px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.footer__links:last-child {
  margin-top: 20px;
}

.footer__links > * {
  margin-bottom: 10px;
}

.footer__links > :not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 650px) {
  .footer__links > :not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 650px) {
  .footer__links > * {
    margin-bottom: 0;
  }
}

.footer__links a {
  text-decoration: underline;
  transition: all .4s;
}

.footer__links a:hover {
  color: #b0b0b0;
}

@media (max-width: 650px) {
  .footer__links {
    flex-direction: column;
    gap: 10px;
  }
}



.btn {
  font-weight: 500;
  font-size: var(--fz-btn);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  transition: all .4s ease-in-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn-orange {
  background-color: var(--color-orange);
  color: #fff;
  box-shadow: 0 8px 13px #ffc3134d;
}

.btn-orange:hover {
  box-shadow: none;
  color: #fff;
  background-color: #ffb949;
}

.btn-white {
  color: var(--color-black);
  background-color: #0000;
  border: 1px solid #eee;
}

.btn-s {
  padding: 10px 20px;
}

.btn-m {
  padding: 15px 30px;
}

.btn-xl {
  padding: 20px 60px;
}

.btn > .flare {
  height: 100%;
  width: 45px;
  background: linear-gradient(90deg, #ffffff1a, #fff6);
  animation: flareAnimation 4s linear 5s infinite;
  position: absolute;
  top: 0;
  left: -150%;
  transform: skewX(-45deg);
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }

  50% {
    left: 150%;
  }

  100% {
    left: 150%;
  }
}

.cookie {
  z-index: 999999;
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  display: none;
  position: fixed;
  bottom: 3%;
  left: 0;
  right: 0;
}

.cookie__wrapper {
  border-radius: var(--border-radius);
  text-align: center;
  background-color: #ffffffe6;
  justify-content: center;
  padding: 10px 25px;
  font-size: 12px;
  display: flex;
  box-shadow: 0 0 30px 5px #0000001a, 0 20px 50px 15px #0000001a;
}

.cookie__wrapper > p {
  margin-right: 15px;
}

.cookie__wrapper > p > a {
  text-decoration: underline;
}

@media (max-width: 650px) {
  .cookie__wrapper {
    padding: 10px;
  }
}

.cookie-close {
  cursor: pointer;
  height: 100%;
}

.cookie-close > svg {
  width: 36px;
  height: 36px;
}

.inputbutton {
  --border: 1px solid #f49a12;
  width: 100%;
  border: var(--border);
  border-radius: var(--border-radius);
  justify-content: space-between;
  gap: 5px;
  padding: 5px 5px 5px 3%;
  display: inline-flex;
}

.inputbutton > input {
  cursor: text;
  background: none;
  flex-basis: 45%;
}

.inputbutton > input::placeholder {
  color: #c4c4c4;
}

@media (max-width: 1100px) {
  .inputbutton > input {
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 15px 10px;
  }
}

.inputbutton > .btn {
  flex-basis: 55%;
}

.inputbutton-helper {
  font-size: var(--fz);
  color: #3e2c46a8;
  margin-top: 10px;
  display: block;
}

@media (max-width: 480px) {
  .inputbutton-helper {
    font-size: 12px;
  }
}

@media (max-width: 1100px) {
  .inputbutton {
    gap: 15pxж;
    border: none;
    flex-direction: column;
    padding: 0;
  }
}

.input {
  font-size: var(--fz);
  color: #fff;
  border-radius: var(--border-radius);
  border: 1px solid #fff;
  padding: 10px 20px;
  display: inline-block;
}

.input::placeholder {
  color: #fff;
}

.input:hover {
  cursor: text;
}

.messengers {
  gap: 5px;
  display: flex;
}

.messengers__item {
  width: 20px;
  height: 20px;
}

.messengers__item > a {
  width: 100%;
  height: 100%;
  display: block;
}

.messengers__item > a > img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.modal {
  z-index: 99999;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #000c;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal:after {
  content: "";
  width: 25px;
  height: 25px;
  z-index: 100;
  background: url("close.46a4f331.svg") center no-repeat;
  display: inline-block;
  position: absolute;
  top: 2%;
  right: 5%;
}

.modal__container {
  width: 40vw;
  max-width: 650px;
  height: auto;
  border-radius: calc(var(--border-radius) * 2);
  cursor: auto;
  background-color: #fefefe;
  border: 1px solid #7a76e580;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#modal1 .modal__container {
  margin: 2% auto;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0);
}

#modal4 .modal__container {
  width: 90%;
  max-width: none;
}

@media (max-width: 768px) {
  .modal__container {
    width: 80%;
  }
}

.modal__body {
  height: 100%;
  text-align: center;
  border: 1px dashed #00000014;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px;
  display: flex;
  position: relative;
}

@media (max-width: 650px) {
  .modal__body {
    padding: 15px;
  }
}

.modal__head > h2 {
  font-size: var(--fz-h2);
  font-weight: 600;
}

.modal__main {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  display: flex;
}

.modal__main > img {
  width: 80%;
  -o-object-fit: contain;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.modal__main > p {
  font-weight: 500;
  font-size: var(--fz-h4);
}

.modal__main > .video {
  margin-bottom: 30px;
  position: relative;
  top: auto;
  left: auto;
}

.modal__main > iframe, .modal__form {
  width: 100%;
}

.browser-mob {
  display: none;
}

.browser-mob__content {
  --width-mob: 270px;
  width: var(--width-mob);
  height: calc(2.03 * var(--width-mob));
  max-height: 646px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.browser-mob__content > img {
  opacity: 0;
  visibility: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: calc(100% - 16px);
  border-radius: 21px;
  transition: all 1s ease-in-out;
  display: none;
  position: absolute;
}

@media (max-width: 768px) {
  .browser-mob__content > img {
    display: block;
  }
}

.browser-mob__content > .active {
  opacity: 1;
  visibility: visible;
  transition: all .5s ease-in-out;
}

.browser-mob__i {
  display: none;
}

@media (max-width: 768px) {
  .browser-mob__i {
    width: 25px;
    height: 25px;
    background-color: var(--color-purple-text);
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    display: flex;
    position: absolute;
    top: 12%;
    left: 48%;
  }
}

@media (max-width: 480px) {
  .browser-mob__i {
    top: 9%;
    left: 46%;
  }
}

@media (max-width: 768px) {
  .browser-mob {
    display: block;
  }
}

.browser__content {
  --width: 85%;
  width: var(--width);
  height: 48vw;
  max-height: 581px;
  background: url("safari.4d055713.svg") top / contain no-repeat;
  border: .5px solid #c3c3c3;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.browser__content > img {
  opacity: 0;
  visibility: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  margin-top: 6%;
  transition: all 1s ease-in-out;
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .browser__content > img {
    display: none;
  }
}

.browser__content > .active {
  opacity: 1;
  visibility: visible;
  transition: all .5s ease-in-out;
}

@media (max-width: 768px) {
  .browser {
    display: none;
  }
}



html {
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

@media (max-width: 650px) {
  html {
    font-size: 14px;
  }
}

b {
  font-weight: 600;
}

:root {
  --color-white: #fcfcff;
  --color-black: #3e2c46;
  --color-orange: #f49a12;
  --color-purple: #7a76e5;
  --color-purple-light: #f0ecff;
  --color-purple-text: #8e72f6;
  --fz: 14px;
  --fz-btn: 16px;
  --fz-h4: 18px;
  --fz-h2: 28px;
  --content-width: 1200px;
  --container-padding-h: 80px;
  --container-padding-w: 30px;
  --border-radius: 5px;
}

@media (max-width: 900px) {
  :root {
    --fz-h2: 24px;
  }
}

@media (max-width: 768px) {
  :root {
    --container-padding-w: 20px;
  }
}

@media (max-width: 650px) {
  :root {
    --container-padding-w: 10px;
    --container-padding-h: 50px;
  }
}

@media (max-width: 480px) {
  :root {
    --fz-h2: 20px;
  }
}

.container {
  max-width: var(--content-width);
  padding-left: var(--container-padding-w);
  padding-right: var(--container-padding-w);
  padding-bottom: var(--container-padding-h);
  margin-left: auto;
  margin-right: auto;
}

.head {
  text-align: center;
  padding-top: var(--container-padding-h);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
}

.head > h2 {
  font-weight: 700;
  font-size: var(--fz-h2);
  line-height: 1.5;
}

.head > p {
  margin-top: 20px;
}

.head_white {
  color: #fff;
}

.item-wrapper {
  border-radius: calc(2 * var(--border-radius));
  background: linear-gradient(0deg, #fcfcff, #fcfcff), #f9f9f9;
  border: 1px solid #7a76e580;
  box-shadow: 0 10px 30px #3e2c461a;
}

[data-animate-on-scroll] {
  opacity: 0;
}

[data-animate-on-scroll="animated"] {
  opacity: 1;
  animation: wipe-enter 2s;
}

@keyframes wipe-enter {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/*# sourceMappingURL=index.7b4a7625.css.map */
