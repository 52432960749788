@import "./00_navbar.css";
@import "./01_header.css";
@import "./02_statistics.css";
@import "./03_problems.css";
@import "./04_opportunities.css";
@import "./05_freetrial.css";
@import "./06_interface.css";
@import "./07_help.css";
@import "./08_economy.css";
@import "./09_5reasons.css";
@import "./10_price.css";
@import "./11_ceo.css";
@import "./12_otzovy.css";
@import "./13_faq.css";
@import "./14_footer.css";
