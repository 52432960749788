.interface {

  &__body {
    position: relative;

    @media (--md) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: var(--fz);
    margin-bottom: 50px;

    & > li {

      & > input[type=radio] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      & > label {
        display: inline-block;
        cursor: pointer;
        padding: 10px 20px;
        user-select: none;
        border-radius: var(--border-radius);
        text-align: center;
        transition: all .4s ease;
      }
       
      /* Checked */
      & > input[type=radio]:checked + label {
        background: rgba(142, 114, 246, .1);
        color: var(--color-purple-text);
      }
       
      /* Hover */
      & > label:hover {
        background: rgba(142, 114, 246, .2);
        color: var(--color-purple-text);
      }
       
      /* Disabled */
      & > input[type=radio]:disabled + label {
        background: #efefef;
        color: #666;
      }
    }
    &-mobile {
      display: none;
      @media (--md) {
        display: block;
        margin-bottom: 30px;
      }

      @media (--xs) {
        width: 72%;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }

    @media (--md) {
      display: none;
    }
  }

  &__item {
    position: absolute;
    width: 240px;
    padding: 30px 20px;
    background: #FFFFFF;
    border: 1px solid #F8F8FA;
    box-shadow: 0px 30px 40px rgba(62, 44, 70, 0.1);
    border-radius: var(--border-radius);
    text-align: left;
    font-size: 12px;
    z-index: 100;

    &-info {
      color: #000;
      top: 30%;

      @media (--xl) {
        display: none;
      }
      @media (--md) {
        display: block;
      }
    }
    &-action {
      color: #000;
      bottom: 8%;

      @media (--lg) {
        width: 200px;
        bottom: 5%;
      }
    }

    & > h4 {
      font-weight: 500;
      font-size: var(--fz);
      margin-bottom: 20px;
    }
    & > p {
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    & > .btn {
      font-size: 12px;
      padding: 10px 12px;
    }

    @media (--md) {
      position: relative;
      width: 50%;
      text-align: center;
      padding: 20px 15px;
    }
    @media (--xs) {
      width: 90%;
    }
  }
}