.browser {

  &__content {
    --width: 85%;
    position: relative;
    background: url(../img/safari.svg) top / contain no-repeat;
    width: var(--width);
    height: 48vw;
    max-height: 581px;
    border: 0.5px solid #c3c3c3;

    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;

    & > img {
      position: absolute;
      left: 0;
      opacity: 0;
      visibility: hidden;
      margin-top: 6%;
      object-fit: contain;
      width: 100%;
      transition: all 1s ease-in-out;

      @media (--md) {
        display: none; /* нужно для унификации работы lazy load */
      }
    }


    & > .active {
      opacity: 1;
      visibility: visible;
      transition: all .5s ease-in-out;
      /* animation: show_screen 1.2s ease-in-out; */
    }
  }

  @media (--md) {
    display: none;
  }
}