.help {
  background-color: var(--color-purple-light);

  &__container {
    /* выравнивание кнопки по центру */
    text-align: center;

  }
  &__body {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 40px;

    @media (--lg) {
      flex-direction: column;
    }
    @media(--xs) {
      text-align: center;
      margin-bottom: 0;
    }
  }

}

.list-help {
  flex-basis: 46%;
  margin-right: 8%;
  
  &__item {
    --img-h: 70px;
    padding: 30px;

    &:not(:last-child) {
      margin-bottom: 75px;
    }

    & > img {
      position: relative;
      top: calc(-0.85 * var(--img-h));
      object-fit: contain; 
      height: var(--img-h);
    }
    & > h4 {
      font-weight: 500;
      font-size: var(--fz-h4);
      color: var(--color-black);
      margin-top: calc(-0.6 * var(--img-h));
      margin-bottom: 20px;
    }
    & > p {
      font-size: var(--fz);
    }
    @media (--xs) {
      padding: 25px;
    }
  }

  @media (--xl) {
    margin-right: 10px;
  }
  @media (--lg) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.speak-manager {
  position: relative;
  display: flex;
  flex-basis: 50%;

  &__card {
    width: 57%;
    height: fit-content;
    position: relative;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    font-size: 12px;

    &:after {
      --size: 18px;
      content:'';
      position: absolute;
      top: 42%;
      right: calc(-2 * var(--size));
      border: var(--size) solid transparent;
      border-bottom-color: #fff;
      transform: rotate(90deg);

      @media (--xs) {
        top: auto;
        bottom: calc(-2 * var(--size));
        right: 50%;
        left: 50%;
        transform: rotate(180deg);
      }
    }

    & > i {
      display: inline-block;
      font-style: italic;
      margin-bottom: 10px;
    }

    @media (--xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;
    }
  }
  &__img {
    position: absolute;
    max-width: 348px;
    width: 100%;
    height: 482px;
    right: 0;
    background: linear-gradient(180deg, rgba(238, 234, 254, 0) 81.98%, #EEEAFE 100%), 
                url(../img/help/help_man.png) center / contain no-repeat;
    
    @media (--lg) {
      position: relative;
    }
    @media (--sm) {
      width: 60%;
      height: 350px;
    }
    @media (--xs) {
      height: 280px;
      background: url(../img/help/help_man.png) bottom / contain no-repeat;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media (--xs) {
    flex-direction: column;
    gap: 40px;
  }
}