.economy {
  &__container {
    
  }

  &__form {
    max-width: 556px;
    margin-right: auto;
    margin-left: auto;

    @media (--xl) {
      width: 50%;
    }
    @media (--sm) {
      width: 100%;
    }
  }
}
.list-economy {
  display: flex;
  justify-content: space-between;
  gap: 100px 20px;
  margin-top: 60px;
  margin-bottom: 40px;

  &__item {
    display: flex;
    flex-direction: column;
    --img-h: 125px;
    position: relative;
    padding: 20px;
    flex-basis: 25%;

    background: linear-gradient(0deg, #FCFCFF, #FCFCFF);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(51, 51, 51, 0.1);
    border-radius: 10px;
    transition: all .5s;

    &:hover {
      transform: translateY(-10px);
    }

    & > img {
      position: relative;
      top: calc(-0.75 * var(--img-h));
      object-fit: contain; 
      height: var(--img-h);
    }

    & > h4 {
      font-weight: 600;
      font-size: var(--fz-h4);
      color: var(--color-black);
      margin-top: calc(-0.5 * var(--img-h));
      margin-bottom: 20px;
    }

    & > p {
      font-size: var(--fz);
    }

    @media (--lg) {
      flex-basis: 47%;
    }
    @media (--sm) {
      --img-h: 100px;
      flex-basis: 100%;
      text-align: center;
    }
  }

  @media (--lg) {
    flex-wrap: wrap;
  }
  @media (--sm) {
    gap: 75px;
  }
}
