.messengers {
  display: flex;
  gap: 5px;
  
  &__item {
    width: 20px;
    height: 20px;

    & > a {
      display: block;
      width: 100%;
      height: 100%;

      & > img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}