.price {
  position: relative;
  background-color: var(--color-purple-light);
  /* z-index: -5; */

  &__container {
    padding-top: 80px;

    @media (--xl) {
      background-color: var(--color-purple);
      border-top: 1px solid rgba(122, 118, 229, 0.5);
      border-bottom: 1px solid rgba(122, 118, 229, 0.5);
      box-shadow: 0px 10px 30px rgba(51, 51, 51, 0.1), inset 0px 5px 50px rgba(255, 255, 255, 0.25);
    }
    @media (--sm) {
      padding-top: 50px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 363px;
      background-color: var(--color-purple);
      border-top: 1px solid rgba(122, 118, 229, 0.5);
      border-bottom: 1px solid rgba(122, 118, 229, 0.5);
      box-shadow: 0px 10px 30px rgba(51, 51, 51, 0.1), inset 0px 5px 50px rgba(255, 255, 255, 0.25);

      @media (--xl) {
        content: none; /* убираем псевдоэлемет и отдаем фон основному контейнеру */
      }
    }
    @media (--sm) {
      padding: 0 10px;
      text-align: center;
    }
  }
  &__img {
    max-height: 500px;
    position: relative;
    width: 40%;
    margin-top: -52px;
    margin-left: -42px;

    & > img {
      height: 100%;
      object-fit: contain;
    }

    @media (--xl) {
      max-height: none;
      height: 350px;
      width: auto;
      margin-top: 0;
      margin-left: 0;
      margin-right: 10px;
    }
    @media (--sm) {
      display: none;
    }
  }

  &__main {
    color: #fff;
    padding-top: 60px;
    z-index: 10;

    & > h2 {
      font-weight: 600;
      font-size: var(--fz-h2);
      margin-bottom: 20px;
    }
    & > p {
      margin-bottom: 40px;
    }
    & > .mobile {
      display: none;

      & > img {
        width: 100%;
        object-fit: contain;
      }

      @media (--sm) {
        display: block;
        width: 65%;
        min-width: 215px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
      }
    }

    @media (--xl) {
      padding-top: 0;
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    @media (--xl) {
      flex-wrap: wrap;
    }
    @media (--sm) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  &__submit {
    display: flex;
    gap: 5px;
    width: 100%;
    /* height: 65px; */

    & > .input {
      flex-basis: 50%;

      @media (--sm) {
        padding: 15px 10px;
      }
    }
    & > .btn {
      flex-basis: 55%;
    }

    @media (--lg) {
      flex-direction: column;
      height: ;
    }
    @media (--sm) {
      height: 100%;
    }
  }
}

.messengers-price {
  display: flex;
  gap: 5px;

  &__item {

    & span {
      font-weight: 400;
      font-size: 10px;
    }

    & > input[type=radio] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    & > label {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      width: 70px;
      padding: 10px 5px;
      user-select: none;
      border-radius: var(--border-radius);
      text-align: center;
      transition: all .4s ease;
    }
     
    /* Checked */
    & > input[type=radio]:checked + label {
      background-color: #fff;
      color: #000;
    }
     
    /* Hover */
    & > label:hover {
      background-color: #fff;
      color: #000;
    }
     
    /* Disabled */
    & > input[type=radio]:disabled + label {
      background: #efefef;
      color: #666;
    }
  }
}